<template>
  <div class="submitted">
    <div class="submitted-wrap">
      <img :src="imgWaiting" class="waiting-img">
      <div class="status">已提交</div>
      <div class="desc" v-if="isshow!=='changeSign'">预计30分钟完成审核</div>
      <van-button @click="submitClick">完成</van-button>
    </div>

  </div>
</template>

<script>
import { Button } from 'vant'
import common from "../../utils/common";
import {appAction, uwStatisticAction} from "../../utils/util";
import {SIGNED_FINISHED} from "../../utils/constantNum";
export default {
  components:{
    [Button.name]: Button
  },
  data() {
    return {
      isshow:'',
      imgWaiting: require('@/assets/img/mine/successIcon.png'),
    }
  },
  created() {
    this.isshow = this.$route.query.sign;
  },
  mounted() {
    this.isshow = this.$route.query.sign;
    console.log('mounted',this.isshow)
    uwStatisticAction('/submitted','站长签约提交成功')
  },
  methods:{
    submitClick(){
      if (common.getItem('jumpFrom') != undefined) {
        appAction(SIGNED_FINISHED)
      }else {
        this.$router.push({name: 'homePage'})
      }

    }
  }
}
</script>

<style lang="less" scoped>
.submitted{
  height: 100%;
  background: #f5f5f5;
  font-size: 0.3rem;
  text-align: center;
  margin-top: 1rem;
  .submitted-wrap{
    background: #fff;
  }
  .waiting-img{
    width: 1.28rem;
  }
  .status{
    margin-top: 0.4rem;
    font-size: 0.36rem;
    color: #333333;
  }
  .desc{
    margin-top: 1rem;
    color: #333333;
  }
  .van-button{
    margin-top: 1.5rem;
    font-size: 0.36rem;
    width: 5.6rem;
    height: 0.98rem;
    border: 0.01rem solid #3F7C53;
    color: #3F7C53;
    background: #fff;
    margin-bottom: 0.68rem;
  }
}
</style>
